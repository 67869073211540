.page-overview {

	display: grid;
	grid-template-columns: max-content 0px 8fr 0px 3fr;
	grid-template-rows: 64px min-content auto;
	gap: 5px 5px;
	grid-template-areas:
		"d r1 m r2 h"
		"d r1 m r2 i"
		"d r1 m r2 c";
	margin: 1em;
}


.view {
	border: 1px solid rgba(0,0,0,0.1);
}

.area {
	/* border: 1px dotted black; */
	padding: 0.5em;
}

.view-current-user {
	grid-area: h;
	/* background-color: #efe; */
}

.view-map {
	grid-area: m;
	/* background-color: #fed; */
}

.view-dataloggers {
	min-width: 150px;
	width: 300px;
	grid-area: d;
	background-color: #fafafa;
	resize: horizontal;
	
}

.view-details {
	grid-area: i;
}

.view-debug {
	/* background-color: #fef; */
	grid-area: c;
	overflow-y: scroll;
}

.panel-resize-bar {
	cursor: ew-resize;
	width: 20px;
	/* border-right: 1px dotted black; */
	z-index: 10;
	transform: translate(-10px,0);
}

#panel-resize-bar-1 {
	grid-area: r1;
}

#panel-resize-bar-2 {
	grid-area: r2;
}

#root > div > div > div.view.view-current-user > div.area.area-user-icon > span {
	background-color: #fff !important;
	color: #a51e12;
}