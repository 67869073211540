@import "../theme.scss";

.view-details {
	display: grid;
	grid-template-rows: auto auto auto min-content;
	grid-template-areas:
	"h"
	"c" 
	"s"
	"f";
	background-color: rgba(0,0,0,0.01);
	width: 100%;
}

.area-details-content {
	grid-area: c;
	padding: 0.5em;
	-webkit-animation: scale-down-center 0.4s cubic-bezier(0.445, 0.050, 0.550, 0.950) both;
	animation: scale-down-center 0.4s cubic-bezier(0.445, 0.050, 0.550, 0.950) both;
	legend {
		font-size: 1.2em;
		font-weight: bolder;
	}
	fieldset {
		height: 100%;
	}
} 

.area-details-content > h3 {
	margin: 0px;
}

.details-table > tr > td{
	padding: 5px;
	vertical-align: top !important;
	background-color: rgba(0,0,0,0.1);
}

.area-details-header {
	grid-area: h;
	a {
		white-space: nowrap;
		margin-right: 1em;
	}
}

.area-details-content {
	.map-list-item {
		padding: 0.2em;
	}
}

.area-details-summary {
	grid-area: s;
	align-self: end;
	padding: 0.2em;
	background-color: rgba(0,64,0,0.01);
	
}


.area-details-footer {
	display: inline;
	grid-area: f;
	align-self: end;
	background-color: rgba(32,0,0,0.05);
	padding: 0.2em;
}

.area-details-footer, .area-details-summary {
	.bp3-button {
		margin: 0.2em;
	}
}

.area-details-map-info {
	padding: 1em;
}

.table-virtual-channel-summary, .table-virtual-channel-vars {
	border-collapse: collapse;
}

.table-virtual-channel-summary {
	td {
		border: 1px solid #ddd;
		padding: 0.5em 0.5em;
	}
}


.area-evaluation-summary {
	table {
		width: 100%;
		margin: -2px;
		border-spacing: 2px;
		th {
			font-weight: normal;
			background: linear-gradient(#e5e5e5, #dddddd);
			padding: 0.5em 0.5em;
			border: none;
		}
		td {
			background: linear-gradient(#f5f5f5, #eeeeee);
			padding: 0.3em 0.5em;
		}
	}
}