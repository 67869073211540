:root
{
	--login-form-height: 240px;
	--login-form-width: 300px;
}


.page-login {
	display: grid;
	grid-template-columns: auto max-content auto;
	grid-template-rows: auto auto auto min-content;
	grid-template-areas: 
		". lg ."
		". fr ."
		". . ."
		"ft ft ft";
	width: 100vw !important;
	height: 100vh !important;
	background: linear-gradient(#fff, #eee);
}

.login-form-container
{
	margin-left: auto;
	margin-right: auto;
	width: var(--login-form-width);
	height: var(--login-form-height);
	padding: 1em;
	background-color: #eee;
	border: 2px outset rgba(0,0,0,0.1);
	border-radius: 0.3rem;
	font-size: 1.2em;
	grid-area: fr;
	
}

.login-btn {
	width: 100%;
	padding: 0.5em;
	font-size: 1.1em;
	margin-top: 0.5em;
}

.wda-logo-container {
	grid-area: lg;
	margin-top: auto;

}

.login-page-footer {
	margin-bottom: 0px !important;
	padding: 1em;
	grid-area: ft;
	align-self:center;
	background-color: #ddd;
	text-align: center;
}