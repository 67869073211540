.modal-virtual-channel-summary {
	width: 60em;
}

.modal-virtual-channel-summary bp3-dialog-body {
	overflow-y: scroll;
	max-height: 30em;
	padding: 1em;
}

.modal-virtual-channel-summary .no-channels-warning {
	color: #888;
	padding: 1em;
	font-size: 22px;
	text-align: center;
}

.modal-virtual-channel-summary table {
	border-collapse: collapse;
}

.modal-virtual-channel-summary .table_loading td {
	color: #999 !important;
}



.modal-virtual-channel-summary table td, th {
	border: 1px solid #999;
	white-space: normal;
	text-align: center;
	padding: 0.2em;
}

.modal-virtual-channel-summary table th {
	font-weight: normal;
}

.modal-virtual-channel-summary table tr:hover {
	background-color: #eee;
}


.modal-virtual-channel-summary .cell-result {
	color: #600;
}

.modal-virtual-channel-summary .cell-value {
	font-weight: 600;
	/*font-family: consolas;*/
}

