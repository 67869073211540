.area-icon-picker {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	overflow-y: scroll;
	width: 500px;
	max-height: 300px;
}

.area-icon-picker .svg-icon-container {
	cursor: pointer;
	margin: 2px;
	padding: 2px;
	background: linear-gradient(#eee, #ddd);
}
