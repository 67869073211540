

.form-field {
	display: grid;
	padding-top: 0.2em;
	padding-bottom: 0.2em;
	margin-top: 0.1em;
	margin-bottom: 0.1em;
	grid-template-columns: 1fr 2fr;
	grid-template-areas:
	"l v";

	background: linear-gradient(rgba(0,0,0,0.01), rgba(0,0,0,0.02));
}

.form-field-vertical {
	grid-template-columns: auto;
	grid-template-areas:
		"l"
		"v";
}

.form-field-vertical .form-field-label {
	text-align: left;
}

.form-field-checkbox {
	display: grid;
	white-space: nowrap;
	grid-template-columns: min-content auto;
	grid-template-areas: 
	"v l";
}

.form-field-checkbox .form-field-label {
	text-align: left;
}

.form-field-label {
	grid-area: l;
	text-align: right;
	padding-top: 0.2em;
	padding-right: 1em;
	user-select: none;
}

.form-field-label-modified {
	font-weight: bold;
}

.form-field-value {
	grid-area: v;
	font-size: inherit;
}

.form .formula-editor {
	width: 570px !important;
}

.form-vchannel {
	display: grid;
	.form-b1-left {
		grid-area: form-b1-left;
	}
	.form-b1-right {
		grid-area: form-b1-right;
	}
	.form-b2-mid {
		grid-area: form-b2-mid;
	}
	.form-b3-mid {
		grid-area: form-b3-mid;
	}
	grid-template-areas: 
		"form-b1-left form-b1-right"
		"form-b2-mid form-b2-mid"
		"form-b3-mid form-b3-mid";
}

.form .form-data-alarm-switch {
	.form-field-value {
		div {
			display: flex;
			padding: 0.5em;
			.bp3-switch {
				margin-top: 0.5em;
				margin-right: 2em;
			}
			button {
				height: 1em;
			}
		}
	}
}

.form-data-alarm {
	margin: 0.5em;
	padding: 0.5em;
	.form-field-frequency {
		.form-field-value {
			input {
				max-width: 5em;
			}
			display: grid;
			.time-interval-input-hours    {grid-area: ih;}
			.time-interval-input-minutes  {grid-area: im;}
			.time-interval-input-seconds  {grid-area: is;}
			.time-interval-label-hours    {grid-area: lh;}
			.time-interval-label-minutes  {grid-area: lm;}
			.time-interval-label-seconds  {grid-area: ls;}
			grid-template-areas: 
				"ih im is"
				"lh lm ls";
		}
		
	}
}

.bp3-tooltip {
	max-width: 35em;
}

.slider-container {
	margin: 1em 0;
	width: 95%;
}

.bp3-datepicker-caption select + .bp3-icon {
	right: 0px !important;
}

.group-virtual-channel-ops {
	.bp3-input {
		width: 15em;
	}
	.form-value-apply {
		.form-field-label {
			div {
				// justify-content: flex-end; // <-- this one also works
				float: right;
			}
		}
		.form-field-value {
			display: flex;
			.bp3-numeric-input {
				.bp3-input {
					width: 6em;
				}
			}
			.btn-verify {
				margin-left: 5px;
				width: 250px;
			}
		}
	}
}