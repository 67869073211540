.channelinfo-tabs-container {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-areas:
		"num ena"
		"tra tra"
		"sat sat";
}

.channelinfo-tab {
	width: auto;
	display: grid;
	grid-template-rows: min-content auto;
	grid-template-areas: 
	"lbl"
	"val";
	margin: 0.1em;
	text-align: center;
	border: 1px solid rgba(0,0,0,0.1);
}

.channelinfo-tab-label {
	background-color: rgba(0,0,0,0.05);
	font-weight: bold;
	grid-area: lbl;
	padding: 0.2em 0.5em;
}

.channelinfo-filter {
	grid-area: sat;	
}

.channelinfo-transformation {
	grid-area: tra;
}

.channelinfo-tab-content {
	text-align: center;
	padding: 0.2em;
}


.channelinfo-transformation .channelinfo-tab-content {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-wrap: wrap;
	flex-direction: row;
	min-width: 2.6em;
}

.channelinfo-filter .channelinfo-tab-content {
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-wrap: wrap;
	flex-direction: row;
}


.channelinfo-coefficient {
	flex: 0 0 auto;
	width: min-content;
}

.channelinfo-number {
	grid-area: num;
}

.chnanelinfo-enabled {
	grid-area: ena;
}


.channelinfo-tab-value {

}