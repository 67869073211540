@import "./components.scss";

.view-map {
	height:100%;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: min-content 0px 100%;
	gap: 0px 0px;
	grid-template-areas:
		"s"
		"sl"
		"m";
	z-index: 18;

	.area-map-selector {
		grid-area: s;
		display: grid;
		align-items: center;
		grid-template-columns: auto min-content;
		grid-template-areas:
			"sel menu";
		
		.map-selector {
			z-index: 2000;
			grid-area: sel;
		}
		
		.map-selector-item {
		
		}
		
		.map-selector-item-selected {
			font-weight: bold;
		}
		
		.map-selector-item-description {
			font-style: italic;
			color: #555;
		}
		
		.map-selector-menu {	
			white-space: nowrap;	
		}
		
		.map-selector-menu .bp3-button {
			margin-left: 0.5em;
		}
			
	}

	.area-map-overlay {
		grid-area: sl;
		z-index: 500;
		margin-left: 4em;
		margin-top: -3em;
		white-space: nowrap;
		width: min-content;
		display: grid;
		.map-label-switch-container {
			background-color: rgba(255,255,255,0.5) !important;
			border: 1px solid rgba(0,0,0,0.2);
			backdrop-filter: blur(10px);
			width: fit-content;
			padding: 0.5em 1em;
			padding-bottom: 0px;
			/* box-shadow: 2px 2px 10px rgba(0,0,0,0.3); */
			align-self: center;
			font-size: 0.9em;
			font-weight: bold;
			/* grid-area: label-switch; */
		}
	}

	.map-placeholder {
		padding: 1em;
		flex-direction: column;
		background: url("../assets/cantiere-background-default.jpg") 100%;
	}

	.map-placeholder-no-cantiere {
		background: url("../assets/cantiere-background-new.jpg") 100%;
		background-size: cover;
		font-weight: 600;
	}

	.map-placeholder, .map-placeholder-no-cantiere {
		height: 94%; // TODO: need fix
		grid-area: m;
		flex-direction: column;
		user-select: none;
		background-size: cover;
		.title {
			color: rgba(0,0,0,0.5);
			font-size: 36pt;
			font-weight: 600;
		}
		a {
			font-size: 12pt;
			text-decoration: none;
		}
	}
}

/*
.area-map-address-bar > ul {
	padding-left: 4em;
	padding-right: 0.5em;
	background: rgba(255,255,255,0.8);
	backdrop-filter: blur(5px);
	width: fit-content;
	border-radius: 0.2rem;	
}



.area-map-address-bar > ul > li {
	

}
*/

.area-map {
	grid-area: m;
	height: 100%;
	min-height: 400px;

}



.leaflet-tooltip {
	opacity: 1 !important;
	border: 2px solid black !important;
}

.leaflet-marker-icon {
	cursor: pointer;
}

.map-marker-active {
	filter: 
		drop-shadow(1px 1px 1px white)
		drop-shadow(-1px -1px 1px white)
		drop-shadow(1px -1px 1px white)
		drop-shadow(-1px 1px 1px white)

		drop-shadow(1px 1px 2px #555)
		drop-shadow(-1px -1px 2px #555)
		drop-shadow(1px -1px 2px #555)
		drop-shadow(-1px 1px 2px #555) !important;
}
