
.address-picker-area {
	width: 100%;
	display: grid;
	grid-template-rows: auto auto;
	grid-template-columns: 150px 150px auto;
	grid-template-areas: 
		"addr addr addr"
		"lat lon s ";
}

.address-picker-address {
	grid-area: addr;
}

.address-picker-lat {
	grid-area: lat;
}

.address-picker-lon {
	grid-area: lon;
}

.address-picker-lon .form-field, .address-picker-lat .form-field {
	width: min-content;
}

.address-picker-lon .bp3-input, .address-picker-lat .bp3-input {
	max-width: 8em;
}

