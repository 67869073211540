
.spoiler {
	cursor: default;
	user-select: none;
	margin: 5px;
	border: 1px solid #aaa;
}

.spoiler-title
{
	padding: 5px;
	background-color: #f5f8fa;
	background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
	box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
	width: 100%;
}

.spoiler-title > .bp3-button-icon
{
	margin: 0em 1em;
}

.spoiler-title .closed:hover
{
	background: var(--button-bg), rgba(125, 188, 255, 0.2);
	box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
}

.spoiler-title:active, .spoiler-title.opened
{
	background-color: #d8e1e8;
	background-image: none;
	box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2);
}

.spoiler-content {
	transition: max-height 1s ease !important; 
	background-color: rgba(128,128,128,0.05);
}

.spoiler-content .closed {
	max-height: 0;
	display: none;
}


.spoiler-content .opened {
	max-height: auto;
}

