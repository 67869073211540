
@import "@blueprintjs/core/lib/scss/variables.scss";

// theme synthesis

$primary-color: #ae2139;
$hover-color: #d12844;
$active-color : #952235;

$pt-intent-primary: $primary-color;
$pt-icon-color-selected: $active-color;
// $pt-dark-icon-color-selected: #851b0d;
$pt-link-color: $primary-color;
$pt-text-selection-color: $pt-intent-primary;

$control-checked-background-color-hover: $hover-color;
$control-checked-background-color-active: $active-color;

//@debug map-set($button-intents, "primary", #0f0, #ff0);
//map-set($button-intents, $intent);

$pt-outline-color: rgba($pt-intent-primary, 0.6);

$minimal-button-background-color-hover: $hover-color;
$minimal-button-background-color-active: $active-color;

$button-intents: (
	"primary": ($primary-color, $hover-color, $active-color),
	"success": ($pt-intent-success, $green2, $green1),
	"warning": ($pt-intent-warning, $orange2, $orange1),
	"danger": ($pt-intent-danger, $red2, $red1)
) !default;

@import "@blueprintjs/core/src/blueprint.scss";

input:focus, textarea:focus, select:focus{
	outline: none;
}

.bp3-menu-item:hover > * {
	color: #fff !important;
}
.bp3-menu-item:active > *{
	color: #fff !important;
}
.bp3-html-select.bp3-minimal select:hover, .bp3-select.bp3-minimal select:hover {
	background: none !important;
}

.css-1pahdxg-control { 
	border-color: $pt-intent-primary !important;
	box-shadow: 0 0 0 1px $pt-intent-primary !important;
}

.css-1pahdxg-control:hover { 
	border-color: $pt-intent-primary !important;
}

::selection {
	color: #fff;
	background-color: $pt-text-selection-color;
}

div.css-9gakcf-option {
	background-color: #eee !important;
	color: #000 !important;
}

[class$="-menu"] .dropdown-row-hint {
	color:  $primary-color;
}

/* css-1n7v3ny-option == hover */

[class$="-menu"] div.css-1n7v3ny-option {
	background-color: $primary-color !important;
	color: #fff !important;
}

[class$="-menu"] div.css-1n7v3ny-option .dropdown-row-hint {
	background-color: $primary-color !important;
	color: #fff !important;
}


[class$="-menu"] .dropdown-row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.dropdown-row-hint {
	color: $primary-color;
}

[class$="-singleValue"] .dropdown-row-hint {
	display: none;
}


.DayPicker-Day[aria-selected="true"] {
	background-color: $primary-color !important;
}


.fake-link {
	text-decoration: underline;
	cursor: pointer;
	color: $primary-color;
}

.show-on-grafana {
	font-weight: bolder;
	font-size: 1.2em;
}

a:link, a:visited {
	color: $primary-color;
}

a:hover {
	color: $hover-color !important;
}

a:active {
	color: $active-color !important;
}

body.bp3-overlay-open {
	overflow: scroll-y !important; /* bp3 hides page scrollbar when opening a modal or context menu which causes the page to shift slightly */
}

.bp3-button { 
	user-select: none;
	cursor: default;
}

.bp3-button.bp3-disabled {
	cursor: default !important;
}

.bp3-tree-node .icon-vchannel > svg {
	fill: $primary-color !important;
}

.bp3-tree-node-selected .icon-vchannel > svg {
	fill: #fff !important;
}

.bp3-tree-node .icon-vchannel-group > svg {
	fill: $active-color;
}

.bp3-callout {
	white-space: normal;
}

.bp3-tab {
	outline: none;
	user-select: none;
	cursor: default;
}

