

.bp3-dialog {
	background: none;
	backdrop-filter: blur(5px) grayscale(1);
	padding: 0px !important;
	border: 2px solid #9b3c4c;
	min-width: 525px;
	width: min-content;
	.bp3-icon-small-cross {
		color: #fff;
	}
	.modal-content {
		padding: 1em;
	}
	.bp3-dialog-header {
		background-color: rgba(255, 0, 34, 0.401);
		background-blend-mode: multiply, luminosity;
		background-size: cover;
		text-shadow: #000 0px 0px 1px;
		border-top-left-radius: 0.25rem;
		border-top-right-radius: 0.25rem;
		mix-blend-mode: exclusion;
	}
	.bp3-dialog-header > * {
		color: #fff !important;
		.bp3-icon {
			color: #fff !important;
		}
	}
	
	bp3-dialog-body {
		background-color: rgb(255,255,255);
	}

	bp3-dialog-footer {
		text-align: center;
		background-color: rgba(255,255,255,0.95);
		padding: 1em;
		border-bottom-right-radius: 0.25rem;
		border-bottom-left-radius: 0.25rem;
		
	}

	.button-bar {
		display: flex;
		justify-content: space-between;
	}

	bp3-dialog-footer > .bp3-button {
		padding: 0.5em 3em;
		margin-left: 0.5em;
		margin-right: 0.5em ;
	}

	.virtual-channel-group-summary-table {
		.bp3-table-quadrant-scroll-container {
			max-height: 25em;
			overflow-y: scroll;
		}
	}
}

.modal-config-device, .modal-config-vchannel {
	width: 700px !important;
}

.modal-new-map {
	min-width: 600px;
	width: auto;
}

.modal-influx-manager {
	min-width: fit-content;
}

.modal-special-chart-wizard {
	padding: 2em;
}

.modal-config-import bp3-dialog-body {
	padding: 1em;
	display: grid;
	width: 75vw;
	height: 600px;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto min-content;
	grid-template-areas: 
		"editor log"
		"message message";
	.area-code {
		grid-area: editor;
		.editor {
			height: 450px;
			width: 100%;
		}
	}
	.area-log {
		grid-area: log;
		width: 100%;
		textarea {
			height: 500px;
		}
	}
	.bp3-callout {
		margin: 0.5em;
		grid-area: message;
	}

}

.busy {
	cursor: progress;
	* {
		cursor: progress
	}
}

.cell-ok {
	font-weight: bolder;
	color: #041;
	background-color: #7fa;
}

.cell-error {
	color: #400;
	background-color: #f97;
}