


.area-influxdb-client {
	margin: 0.5em;
	display: grid;
	grid-template-columns: 1fr max-content 1fr;
	grid-template-areas: 
		"db tr tb"
		"sum sum sum"
		"ft ft ft";
	
	.area-table-selector  {
		grid-area: db;
	}

	.area-timerange-selector  {
		grid-area: tr;
	}

	.area-toolbar {
		padding-top: 1em;
		grid-area: tb;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		align-content: flex-start;
		.bp3-button, .form-field-checkbox {
			margin: 0.2em 0.5em;
		}
		.bp3-button {
			min-width: max-content;
			width: 15em;
			
		}
	}

	.area-summary-table  {
		grid-area: sum;
	}

	.area-summary-footer {
		grid-area: ft;
	}
	
	
}

.popover-delete-confirmation {
	padding: 1em;
	.bp3-button {
		width: 5em;
		margin: 0.3em;
	}
}

.bp3-timezone-picker-popover > .bp3-popover-content {
	max-height: 20em !important;
	overflow-y: auto;
}

.area-summary-table {
	.bp3-table-quadrant-scroll-container {
		max-height: 25em;
		overflow-y: scroll;
	}
}

.busy_loading, .busy_loading *, .busy_loading > * {
	cursor: progress !important;
}