.image-upload-area {
	width: 95%;
	height: 240px;
	border: 1px dashed black;
	text-align: center;
	padding: 1em;
	position: relative;
	margin: 1em;
}

.image-upload-area .bp3-button
{
	margin: 2em;
	padding: 0.5em;
	transform: scale(1.2,1.2);
}

.image-upload-area-overlay
{
	position: absolute;
	width: 100%;
	height: 100%;
	left:0;
	top:0;
	background-color: rgba(0,0,0,0.1);

	display: none;
}

.image-upload-area-overlay .drag-hint
{
	margin-top: 2em;
	align-items: center;
	vertical-align: middle;
	font-size: 40px;
	color: #fff;
	display: none;
}

.image-upload-area-overlay.drag-over
{
	background-color: rgba(0,0,0,0.5);
	display: block;
	
}

.image-upload-area-overlay.drag-over .drag-hint
{
	display: block;
}

.input-file
{
	border: 1px solid rgba(0,0,0,0.1);
	padding: 1em;
	background-color: rgba(0,0,0,0.1);
	width: 100%;
}

.image-upload-modal
{
	min-width: 40em;
}

.area-image-preview {
	display: grid;
	grid-template-columns: max-content max-content max-content auto;
	grid-template-rows: 1fr 1fr 1fr 2fr;
	grid-template-areas: 
		"pic name-label name-value s"
		"pic size-label size-value s"
		"pic res-label res-value s"
		"pic x x x";
	background-color: #eee;
	border: 1px solid #ccc;
}

.area-image-preview > .res-label {
	grid-area: res-label;
	padding: 0em 1em;
}

.area-image-preview > .res-value {
	grid-area: res-value;
}

.area-image-preview > .size-label {
	grid-area: size-label;
	padding: 0em 1em;
}

.area-image-preview > .size-value {
	grid-area: size-value;
}

.area-image-preview > .name-label {
	grid-area: name-label;
	padding: 0em 1em;
}

.area-image-preview > .name-value {
	grid-area: name-value;
}
.area-image-preview > img {
	grid-area: pic;
}
