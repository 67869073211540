

.file-picker-list-entry, .modal-file-picker .bp3-overflow-list {
	margin: 0 1em;
	margin-bottom: 1px;
	margin-top: 1px;
	padding-left: 1em;
	padding-top: 0.2em;
	padding-bottom: 0.2em;
}

.file-picker-list-view {
	height: 30em;
	overflow-y : scroll;
	user-select: none;
}

.file-picker-list-view .empty-dir-label {
	text-align: center;
	color: #888;
	font-size: 16pt;
	padding: 1em;
}

.file-picker-list-entry:hover, .file-picker-list-entry-selected {
	background-color: #963141;
	color: #fff;
}

.file-picker-list-entry-selected {
	background-color: #65212c !important;
}

b.modal-file-picker .file-picker-list-entry:nth-of-type(even) {
	background-color: #eee;
}

.file-picker-list-entry .bp3-icon {
	margin-right: 0.5em;
}

.file-picker-list {
	border: 1px solid #555;
	height: 30em;
	overflow-y: scroll;
	overflow-x: scroll;
}

.file-picker-toolbar {
	padding: 0.5em;
	padding-left: 1.8em;
	background-color: #eee;
}

.file-picker-toolbar .bp3-button {
	margin: 0em 0.2em;
}

.bp3-dialog .bp3-breadcrumbs {
	padding-left: 1em;
}