
.table-debug td {
	vertical-align: top;
	background-color: rgba(0,0,0,0.05);
}

.table-debug td.value {
	
}

.table-debug td.key{
	
}