

.landing-page {
	position: absolute;
	background: url("../assets/cantiere-background-new.jpg") 100%;
	background-size:cover;
	display: grid;
	height: 100%;
	width: 100%;
	grid-template-columns: auto max-content auto;
	grid-template-rows: auto max-content auto;
	grid-template-areas: 
		". . ."
		". c ." 
		". . .";
	.content {
		grid-area: c;
		display: flex;
		flex-direction: row;
		gap: 5em;
		.menu-element {
			border: 10px solid rgba(0,0,0,0);
			box-shadow: 1px 1px 20px rgba(128,128,128,0.3);
			.title {
				width: 100%;
				text-align: center;
				background: #fff;
				padding: 0.2em;
				margin-top: -5px;
				//color: #fff;
				font-size: 20pt;
				font-weight: 500;
				/* backdrop-filter: blur(5px) */
			}
		}
		.menu-element:hover {
			border: 10px solid rgba(0,0,0,0.1);
			//box-shadow: none;
		}

		.menu-element:active {
			filter:brightness(0.9);
		}
		
	}
}


