.state-debug-panel {
	/* position: absolute; 
		float: right;
		top: 50px;
		right: 0px;
	
	background-color: rgba(255,255,192,0.5);
	padding: 1em;
	margin: 1em;
	border: 1px dashed rgba(160,128,64,0.5)
	*/
}

.view-debug {

}

.area-control-panel {
	font-size: 0.9em;
}

.area-control-panel .bp3-button {
	min-width: 14em;
}
