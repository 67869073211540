
.area-media-manager {
	width: max-content;
	margin: 0.5em;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: min-content auto min-content;
	grid-template-areas: 
		"u u u"
		"g g g"
		"s s s";
	.area-upload  {
		grid-area: u;
	}
	.area-file-table {
		grid-area: g;
		max-height: 60vh;
		overflow-y: auto;
		table {
			width: min-content;
		}
		td, th {
			font-weight: normal;
			padding: 0.33em 1em 0.33em 1em;
			border: none;
			text-align: left;
		}
		.td-cantiere-name {
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		.td-name {
			cursor: pointer;
		}
		tr:nth-child(odd) {
			background-color: rgba(0, 0, 0, 0.05);
		}
	}
}

.media-thumbnail {
	margin: 5px;
	position: relative;
	img {
		max-width: 250px;	
		max-height: 250px;
	}
}
