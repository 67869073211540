
.alert {
	opacity: 0.9;
}

.alert-caption {
	margin-bottom: 0.5em;
	font-weight: bold;
	font-size: 1.2em;
}

.alert-caption .bp3-icon {
	margin-bottom: 0.2em;
	margin-right: 0.5em;	
}

.alert-text {
	border-top: 1px solid rgba(0,0,0,0.1);
}