

.view-current-user {
	display: grid;
	grid-template-columns: auto min-content min-content;
	grid-template-rows: auto;
	gap: 0px 0px;
	grid-template-areas:
		"x i n";
}

.area-user-icon {
	grid-area: i;
	width: 64px;
}

.area-user-name {
	grid-area: n;
	width: 10em;
}