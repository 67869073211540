

.dropdown {
height: 2em;
}


@mixin centered {
	display: flex;
	justify-content: center; /* align horizontal */
	align-items: center; /* align vertical */
}

@mixin fullheight {
	position: absolute;
	height: 100%;
	width: 100%;
}