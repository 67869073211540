.bp3-tree-node-content > .bp3-icon:nth-child(2) {
	margin-right: 1em;
}

.bp3-tree-node-content {
	border-bottom: 1px solid rgba(0,0,0,0.05);
}

.bp3-tree-node {
	margin-top: -5px;
	margin-left: -5px;
}


.bp3-tree-node-selected {
	span.icon-vchannel-group svg {
		fill: #fff !important; // change icon colors to white
	}
	span.bp3-tag {
		color: #fff !important; // change badge color to white
	}
}

.bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node {
	background-color: rgb(174, 33, 64);
	font-weight: bolder;
}

:not(.bp3-tree-node.bp3-tree-node-selected) > .bp3-tree-node-content:hover {
	background-color: rgba(0,0,0,0.05);
	z-index: -1;
}

.view-dataloggers {
	display: grid;
	height: min-content;
	grid-template-columns: 1fr;
	grid-template-rows: auto 90vh;
	gap: 0px 0px;
	grid-template-areas:
		"s"
		"d";
	user-select: none;

	.area-cantiere-selector {
		/* background-color: rgba(0,0,0,0.1); */
		grid-area: s;
		justify-self: center;
		width: 100%;
		height: min-content;
		padding: 0px !important;
		display: grid;
		align-items: center;
		grid-template-columns: auto min-content;
		grid-template-rows: auto auto;
		grid-template-areas: 
			"sel menu"
			"qs qs";
		.cantiere-selector {
			margin-left: 0.5em;
			grid-area: sel;
		}

		.quick-search-datalogger {
			grid-area: qs;
			width: 100%;
			padding: 5px;
			z-index: 0;
		}
		input {
			border-radius: 3px;
		}
	}

	.area-datalogger-list {
		grid-area: d;
		overflow-y: scroll;
		display: flex;
		flex-flow: column;
	
		.empty-area-text {
			font-size: 24pt;
			text-align: center;
			color: #aaa;
		}
	}
}



.cantiere-selector-menu {
	grid-area: menu;
	white-space: nowrap;
	padding: 0 0.5em;
	.bp3-button {
		margin-left: 0.2em;
	}
}


.area-datalogger-tree-ctx-menu-area {
	flex: 1 1 auto;
}
