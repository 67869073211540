.element-group-caption
{
	position: relative;
	padding: 0 0.5em 0 0.5em;
	user-select: none;
	font-size: inherit;
}

.element-group
{
	border: 1px solid #bbb;
	border-radius: 0.25rem;
	margin: 2px;
	margin-left: 0.5em;
}

.form-label
{
	user-select: none;
}